@font-face {
    font-family: "Favorit";
    font-weight: 300;
    src: url("../../../../node_modules/@liqd/liqd-fonts/Favorit-LightMono.otf") format("opentype");
}

@font-face {
    font-family: "Plantin";
    font-weight: 400;
    src: url("../../../../node_modules/@liqd/liqd-fonts/PlantinMTPro-Regular.otf") format("opentype");
}
