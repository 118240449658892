.media-with-caption {
    display: table;
}

.media-with-caption__figcaption {
    display: table-caption; // stops caption oveflowing floated img
    caption-side: bottom;

    font-family: $font-family-monospace;
    font-size: $font-size-xs !important;

    padding: 0.5rem 0;
    margin-bottom: 0;
    text-transform: none;

    @include media-breakpoint-down(sm) {
        padding: 0.5rem 0 0.5rem 0.5rem;
        font-size: 0.7rem;
        line-height: 1.5;
    }
}

.media-with-caption__copyright {
    color: $text-light;
}
