body {
    font-size: $font-size-md;

    @include media-breakpoint-up(sm) {
        font-size: $font-size-base;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size-xl;
    }

    // push content down because of fixed header
    padding-top: 8rem;

    max-width: 1920px;
    margin: 0 auto;

    overflow-x: hidden;
}

// This should be width but needs to be looked at with challeng tiles
// .container {
//     max-width: 960px;
// }

// override padding-bottom because there should be no space above the content
.project-page {
    padding-top: 0;
}

img {
    max-width: 100%;
    height: auto;
}

input[type="submit"],
input[type="button"],
button {
    border: 0;
    padding: 0.5rem;

    background-color: transparent;
    border-radius: 0;

    font-family: inherit;

    appearance: none;
}

a {
    border-bottom: 1px solid currentColor; /* stylelint-disable-line */

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

h1,
.h1 {
    font-size: $font-size-h1 * $font-size-mobile;
    letter-spacing: 0.36px;
    margin-bottom: 1.5rem;
    line-height: 1.25;

    @include media-breakpoint-up(md) {
        font-size: $font-size-h1;
    }
}

h2,
.h2 {
    font-size: $font-size-h2 * $font-size-mobile;
    letter-spacing: 0.4px;
    margin-bottom: 1.5rem;
    line-height: 1.4;

    @include media-breakpoint-up(md) {
        font-size: $font-size-h2;
    }
}

h3,
.h3 {
    font-size: $font-size-h3;
    letter-spacing: 0.36px;
    margin-bottom: 1.5rem;
    line-height: 1.35;
}

h4,
h5,
.h4,
.h5 {
    font-family: $font-family-monospace;
    margin-bottom: 1.5rem;
    font-weight: 300;
    line-height: 1.4;
}

h4,
.h4 {
    font-size: $font-size-h4;
    text-transform: uppercase;
    letter-spacing: 0.05px;
}

h5,
.h5 {
    font-size: $font-size-h5 !important;
    letter-spacing: 0.5px;
}

.rich-text {
    b,
    strong {
        font-weight: 400;
    }

    i,
    em {
        font-style: normal;
    }

    li {
        margin-bottom: 0.8rem;
    }
}

.lead {
    font-size: 1.35rem;

    @include media-breakpoint-up(md) {
        font-size: $lead-font-size;
    }
}

.text--upper {
    text-transform: uppercase;
}

// Accordion component using details and summary elements styling
details summary {
    cursor: pointer;
}

details summary > * {
    display: inline;
}
