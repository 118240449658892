// --------------------------------------------------
// Form styles
// --------------------------------------------------

.styled-select {
    border: 1px solid black;
    display: inline-block;
    position: relative;

    select {
        background: transparent;
        border: none !important;
    }
}
