$line-bottom: 0.2rem;

.person-list__person {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 3rem;

    overflow: hidden;

    position: relative;

    &:after,
    &:before {
        content: "";
        display: block;
        border-bottom: 1px solid black;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        transition: bottom $transition-duration - 0.1s;
    }

    @include media-breakpoint-up(sm) {
        width: auto;
        margin-right: 1rem;
    }
}

.person-list__person-link {
    border-bottom: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;

        .person-list__person:after {
            bottom: 99%;
        }
    }
}

.person-list__person-name {
    font-size: $font-size-xl;
    display: inline-block;
    margin-bottom: 0;
    margin-right: 1.5rem;
}

.person-list__person-image {
    vertical-align: text-bottom;
}
