.academy__sans-serif {
    font-family: $font-family-monospace;
    font-size: $font-size-h4;
}

.academy__teaser-header {
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
}

.academy__btn--back {
    height: 1rem;
}

.academy__spacer {
    padding-bottom: 2 * $spacer;
}
