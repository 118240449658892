.fade-teaser {
    padding-bottom: 0.5em;
    position: relative;
}

.fade-teaser:before {
    content: "";
    display: block;
    height: 7em;

    position: absolute;
    right: -0.5em;
    bottom: 0;
    left: -0.5em;
    z-index: 1;

    background: linear-gradient(to bottom, rgba($body-bg, 0) 0%, rgba($body-bg, 1) 75%);

    transform: rotate(-1deg);
}

.fade-teaser__body {
    transition: transform $transition-duration;
}

.fade-teaser__link {
    display: block;
    border-bottom: 0;

    position: absolute;
    inset: 0;
    z-index: 2;

    &:hover + .fade-teaser__body,
    &:focus + .fade-teaser__body {
        transform: translateY(-1em);
    }
}
