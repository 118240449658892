.simple-header,
.simple-header-bg {
    margin-bottom: $spacer-v;
}

.simple-header-bg {
    padding: ($spacer-v * 0.75) 0;

    @include media-breakpoint-up(md) {
        padding: $spacer-v 0;
    }
}
