.pagination__list {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: none;
    margin-bottom: 2rem;
}

.pagination__link {
    border: none;
}

.pagination__icon--next,
.pagination__icon--prev {
    height: 1rem;
}

.pagination__icon--prev {
    transform: rotate(180deg);
}
