.academy-list__topic-parent {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacer-v * 0.5;
}

// tablet size
.academy-list__topic {
    font-size: $font-size-base;
    font-family: $font-family-base;
    text-align: center;
}

.academy-list__topic--link {
    border: none;
}

.academy-list__topic-square {
    width: 180px;
    height: 170px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &:hover {
        background-color: $body-bright-bg;
    }
}

.academy-list__topic-selected-square {
    background-color: $body-bright-bg;
    position: relative;
}

.academy-list__topic-selected-arrow {
    position: absolute;
    left: 75px;
    bottom: -15px;
    width: 30px;
    height: 30px;
    background-color: $body-bright-bg;
    transform: rotate(45deg);
    z-index: -1;
}

.academy-list__filter--md {
    display: none;
}

.academy-list__intro {
    margin-bottom: $spacer-v * 0.5;
}

.academy-list__topic-img {
    height: 110px;
    width: auto;
}

.academy-list__item img {
    width: 100%;
}

// desktop size
@include media-breakpoint-up(md) {
    .academy-list__filter--sm {
        display: none;
    }

    .academy-list__filter--md {
        display: block;
    }

    .academy-list__topic-square {
        width: 220px;
        height: 210px;
    }

    .academy-list__topic-selected-arrow {
        left: 95px;
        bottom: -15px;
    }
}
