.filter-line {
    margin-bottom: $spacer-v + 2rem;
    padding: 0 $spacer;

    @include media-breakpoint-up(md) {
        display: flex;
        padding: 0;
        justify-content: center;
        align-items: center;

        &.academy-list__sorting {
            justify-content: flex-end;
            margin-bottom: $spacer-v * 0.5;
        }
    }
}

.filter-line--centered {
    @include media-breakpoint-up(md) {
        text-align: center;
    }
}

.filter-line__prefix {
    margin-bottom: 0;
}

// prefix is a span, therefore not the first element and needs space to the left
span.filter-line__prefix {
    @include media-breakpoint-up(md) {
        margin-left: 0.3em;
    }
}

.filter-line__current {
    padding: 0 1.5rem 0 0;

    position: relative;

    font-size: $font-size-h1;
    color: $body-color;
    border-bottom: 1px solid $body-color;

    @include media-breakpoint-up(md) {
        margin-left: 0.3em;
    }

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 7px 0;
        border-color: #000 transparent transparent; /* stylelint-disable-line */

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}

.filter-line__current--active {
    display: none;
}

.filter-line__current--small {
    font-size: $font-size-base;
    width: 100%;
    text-align: left;
}

.filter-line__list {
    display: none;
    padding: 0;
    margin: 0;

    text-align: left;
    list-style: none;
    vertical-align: middle;

    @include media-breakpoint-up(md) {
        margin: 0 0 0 1.5em;
    }
}

.filter-line__modal-close {
    position: absolute;
    right: $spacer - $navbar-toggler-padding-x;
    top: 1rem + 0.7rem;
}

.filter-line__list--active {
    display: block;
}

.filter-line__item {
    line-height: 2;
}

.filter-line__item--active span {
    border-bottom: 1px solid $body-color;
}

.filter-line__link,
.filter-line__link:hover,
.filter-line__link:focus {
    text-decoration: none;
}

.filter-line__modal {
    display: flex;
    padding: 2rem;
    align-items: center;

    position: fixed;
    inset: 0;
    z-index: 4;

    background: rgba($body-bg, 0.95);

    .filter-line__list {
        display: block;

        font-size: $font-size-xl;
    }

    li {
        margin-bottom: 1.5 * $spacer;
        line-height: 1.4;
    }
}
