.intro-screen {
    min-height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacer-v;

    h1 {
        margin-bottom: 0;
        color: inherit;
    }
}

.project-page .intro-screen {
    text-align: center;
}

.hero-bg .intro-screen {
    min-height: auto; // reset the minheight for academy LP
}
