// --------------------------------------------------
// Basic block styles
// --------------------------------------------------

.block {
    margin-bottom: $spacer-v * 0.5;

    @include media-breakpoint-up(sm) {
        margin-bottom: $spacer-v;
    }

    li,
    p {
        font-size: $font-size-base;
    }
}

.block--paragraph {
    p:last-child {
        margin-bottom: 0;
    }
}

.block--image {
    @include media-breakpoint-up(sm) {
        max-width: 80vw;

        &:nth-child(odd) {
            margin-left: auto;
        }
    }
}

.block--aligned_image {
    width: 100%;
}

.block--aligned_image--right {
    @include clearfix;

    .media-with-caption {
        float: right;
    }
}

.block--aligned_image--left {
    @include clearfix;

    .media-with-caption {
        float: left;
    }
}

.block--aligned_image--left img,
.block--aligned_image--right img,
.block--image img {
    max-height: 80vh;
}

.block--teaser_list {
    h2 {
        text-align: center;

        @extend .h4;
    }

    a {
        border-bottom: none;
    }

    .col-md-6 {
        margin-bottom: $spacer-v * 0.5;
    }

    .row:last-child {
        margin-bottom: 0;
    }

    .fade-teaser:before {
        height: 12rem;
    }
}

.block--all_persons_list h2,
.block--projects h2,
.block__col-cta h2 {
    text-align: center;

    @extend .h4;
}

.collapse-mt + .collapse-mt {
    .block {
        margin-top: 0;
    }
}

.block--all_persons_list__container {
    padding: 1em;
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity $transition-duration - 0.1s;

    h3 {
        text-align: left;
        margin-bottom: 1.5rem;
    }
}

.block--all_persons_list__item {
    position: relative;

    a {
        color: inherit;
    }

    &:hover .block--all_persons_list__container {
        opacity: 1;
    }
}

.block--call_to_action_teaser {
    margin-bottom: 0;
}

.block--call_to_action_teaser:last-child {
    margin-bottom: -$spacer-v;
}

.block__col-cta--dark {
    background-color: $black;
    color: $white;
    text-align: center;
    margin-bottom: 0;

    .block__col-cta__border {
        @include media-breakpoint-up(md) {
            border-right: 1px solid $white;
            margin: $spacer-v 0;
        }
    }

    a {
        color: $white;
    }

    .block__col-cta {
        padding: ($spacer-v * 0.75) 0;

        @include media-breakpoint-up(md) {
            padding: $spacer-v 0;
        }
    }
}

.block--single_teaser img {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.academy-block__icon {
    width: 81px;
    height: 75px;
    object-fit: contain;
    display: block;
}
