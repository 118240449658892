.progress-circle__line {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
        border-right: dotted 3px #72e3e1;
        position: absolute;
        top: 0;
        bottom: 200px;
        right: 120px;
        z-index: -1;
    }

    @include media-breakpoint-up(xl) {
        right: 124px;
    }
}

.progress-circle__arrow {
    margin-top: 1rem;
    transform: rotate(-90deg);
    height: 20px;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.progress-circle__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    margin-left: auto;
}

.progress-circle {
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    font-size: 0;
    background: linear-gradient(135deg, #72e3e1, #87adea 100%); // back-up
    background: conic-gradient(#72e3e1, #87adea, #72e3e1 var(--progress), #ebebeb 0deg);
    animation: 1s ease-out turn-in reverse;
}

.progress-circle:after {
    content: attr(data-steps);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 8px;
    border-radius: 50%;
    background: $body-bg;
    font-size: 1rem;
    text-align: center;
    font-family: $font-family-monospace;
}

@keyframes turn-in {
    5% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.95), #ebebeb 0deg);
    }

    10% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.9), #ebebeb 0deg);
    }

    15% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.85), #ebebeb 0deg);
    }

    20% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.8), #ebebeb 0deg);
    }

    25% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.75), #ebebeb 0deg);
    }

    30% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.7), #ebebeb 0deg);
    }

    35% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.65), #ebebeb 0deg);
    }

    40% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.6), #ebebeb 0deg);
    }

    45% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.55), #ebebeb 0deg);
    }

    50% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.5), #ebebeb 0deg);
    }

    55% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.45), #ebebeb 0deg);
    }

    60% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.4), #ebebeb 0deg);
    }

    65% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.35), #ebebeb 0deg);
    }

    70% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.3), #ebebeb 0deg);
    }

    75% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.25), #ebebeb 0deg);
    }

    80% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.2), #ebebeb 0deg);
    }

    85% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.15), #ebebeb 0deg);
    }

    90% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.1), #ebebeb 0deg);
    }

    95% {
        background: conic-gradient(#72e3e1, #87adea calc(var(--progress) * 0.05), #ebebeb 0deg);
    }

    100% {
        background: conic-gradient(gray 0deg);
    }
}
