.project-teaser__container {
    padding: 1rem;
    opacity: 0;
    transition: opacity $transition-duration - 0.1s;

    @include media-breakpoint-up(sm) {
        padding: 2rem;
        position: absolute;
        inset: 0;
    }
}

.project-teaser {
    position: relative;

    a {
        color: inherit;
        display: inline-block;
        margin-right: 1em;
    }

    &:hover .project-teaser__container {
        opacity: 1;
    }

    @include media-breakpoint-up(xl) {
        min-width: 970px;
    }
}

// replace image on md screens us as on small screen content overflowed image
.project-teaser__image--sm {
    @include media-breakpoint-up(sm) {
        background-image: none;
    }
}

.project-teaser__image--md {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
        width: 100%;
    }
}

.project-teaser__container__header,
.project-teaser__container__header:hover {
    border-bottom: none;
}

.project-teaser--left {
    float: left;
}

.project-teaser--right {
    float: right;
}
