.fact-list {
    overflow: hidden;
    margin-bottom: $spacer-v;

    dt {
        width: 7em;
        float: left;

        font-weight: 400;
    }

    dd {
        overflow: hidden;
        margin-bottom: 0.2rem;
    }

    p {
        margin-bottom: 0;
    }
}
