.lang-switch {
    position: absolute;
    top: 4.5rem;
    right: 1rem;

    @include media-breakpoint-up(md) {
        top: 1.25rem;
        right: 1.5rem;
    }
}

.lang-switch__active {
    padding-bottom: 0;
    text-transform: uppercase;
}

.lang-switch__active,
.lang-switch__link {
    font-family: $font-family-monospace;
    font-size: $font-size-xs;
}

.lang-switch__link {
    padding-right: 0;
    padding-left: 0;
}

.lang-switch__list {
    display: none;
    padding: 0;
    margin: 0 1em 0 0;

    position: absolute;
    right: 100%;
    top: 0;

    list-style: none;

    transition: transform $transition-duration, opacity $transition-duration - 0.05;
    opacity: 0;
    transform: translateX(1.5em);
}

.lang-switch__list--active {
    transform: translateX(0);
    opacity: 1;
}
