.row-right {
    display: flex;
    flex-wrap: wrap;

    @each $breakpoint in map-keys($container-max-widths) { /* stylelint-disable-line */
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($grid-gutter-widths, $breakpoint);
            $max-width: map-get($container-max-widths, $breakpoint);
            margin-left: calc((100% - #{$max-width}) / 2);
            margin-right: 0;
        }
    }

    @include media-breakpoint-only(xs) {
        $gutter: map-get($grid-gutter-widths, sm);
        margin-right: ($gutter * 0.5);
    }

    > :last-child {
        padding-right: 0;
    }
}

.row-left {
    display: flex;
    flex-wrap: wrap;

    @each $breakpoint in map-keys($container-max-widths) { /* stylelint-disable-line */
        @include media-breakpoint-up($breakpoint) {
            $gutter: map-get($grid-gutter-widths, $breakpoint);
            $max-width: map-get($container-max-widths, $breakpoint);
            margin-right: calc((100% - #{$max-width}) / 2);
            margin-left: 0;
        }
    }

    @include media-breakpoint-only(xs) {
        $gutter: map-get($grid-gutter-widths, sm);
        margin-left: ($gutter * 0.5);
    }

    > :last-child {
        padding-left: 0;
    }
}
