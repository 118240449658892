$black: #101010;

$body-bg: #fbfbfb;
$body-bright-bg: #f0f0f0;
$body-color: #060606;
$text-light: #858585;
$inverse-bg: $body-color;
$inverse-color: $body-bg;

$link-color: $body-color;
$link-decoration: none;
$link-hover-color: scale-color($link-color, $lightness: 15%);

$font-family-base: 'Plantin', 'Times New Roman', serif;
$font-family-monospace:  'Favorit', monospace;

$font-size-xl: 1.4375rem;
$font-size-base: 1.3125rem;
$font-size-md: 1.2rem;
$font-size-sm: 1rem;
$font-size-xs: 0.8rem;
$line-height-base: 1.4;

$font-size-h1: 2.4rem;
$font-size-h2: 2rem;
$font-size-h3: 1.5rem;
$font-size-h4: 0.8rem;
$font-size-h5: 0.8rem;

$font-size-mobile: 0.8;

$spacer: 1rem;
$spacer-v: 5rem;
$headings-margin-bottom: $spacer-v * 1.2;

$lead-font-size: 2rem;
$lead-font-weight: 400;

$grid-gutter-width-base: 2.6rem;
$grid-gutter-widths: (
    xs: $grid-gutter-width-base * 0.7,
    sm: $grid-gutter-width-base * 0.5,
    md: $grid-gutter-width-base,
    lg: $grid-gutter-width-base,
    xl: $grid-gutter-width-base
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1440px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1000px
);

$transition-duration: 0.3s;

$border-color: scale-color($body-color, $lightness: 85%);
