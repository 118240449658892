.blog-entry {
    > h4 {
        text-align: center;
        margin-bottom: 5 * $spacer;
    }
}

.blog-entry__author {
    text-align: center;
}
