.blog-list {
    margin-bottom: $spacer-v;

    h2 {
        text-align: center;

        @extend .h4;
    }
}

.blog-list__item {
    margin-bottom: $spacer-v * 0.5;
}

.blog-list__item-date {
    margin-bottom: $spacer-v * 0.5;
}

.blog-list__item-title {
    text-decoration: none;
}
